exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-etiquette-js": () => import("./../../../src/pages/etiquette.js" /* webpackChunkName: "component---src-pages-etiquette-js" */),
  "component---src-pages-group-x-js": () => import("./../../../src/pages/group-x.js" /* webpackChunkName: "component---src-pages-group-x-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kidz-inc-js": () => import("./../../../src/pages/kidz-inc.js" /* webpackChunkName: "component---src-pages-kidz-inc-js" */),
  "component---src-pages-memberships-js": () => import("./../../../src/pages/memberships.js" /* webpackChunkName: "component---src-pages-memberships-js" */),
  "component---src-pages-personal-trainers-js": () => import("./../../../src/pages/personal-trainers.js" /* webpackChunkName: "component---src-pages-personal-trainers-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-strongman-js": () => import("./../../../src/pages/strongman.js" /* webpackChunkName: "component---src-pages-strongman-js" */),
  "component---src-pages-tanning-js": () => import("./../../../src/pages/tanning.js" /* webpackChunkName: "component---src-pages-tanning-js" */),
  "component---src-pages-team-training-js": () => import("./../../../src/pages/team-training.js" /* webpackChunkName: "component---src-pages-team-training-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

